header {
  background: #494646;
  display: flex;
  filter: drop-shadow(0 0.1rem 0rem #cfb2b2);
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100vw;
  height: 7.55rem;

  .company {
    background: #feffff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-right-radius: 0.2rem;
    width: 20vw;
    padding: 0.3rem;
    font-family: "Anek Gurmukhi";
    filter: drop-shadow(0.1rem 0.15rem 0.15rem #e8ebe5);

    .logo {
      position: relative;
      height: 3.1rem;
      width: 3.1rem;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.08);
      }
    }

    .name {
      position: relative;
      margin: 0.1rem 2vw 0 2vw;
      padding: 0;
      font-size: 2.1rem;

      .I,
      .C {
        font-size: 1.6rem;
        padding: 0 0.6vw 0 1vw;
      }
    }

    .code {
      margin: 0;
      padding: 0;
      font-size: 0.4rem;
      height: 0.45rem;
      line-height: 0.999rem;
      font-family: exo;

      @media only screen and (orientation: landscape) {
        font-size: 0.66rem;
      }
    }
  }

  .activator {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 2.5rem;
    right: 6vw;
    overflow: hidden;
    background: transparent;
    border: none;

    @media only screen and (orientation: landscape) {
      display: none;
    }

    .line {
      background: #ffffff;
      position: relative;
      height: 0.1rem;
      margin: 0.22rem 0;
      width: 1.8rem;
      border: solid 0.1rem #ffffff;
      border-radius: 0.1rem;
      transform-origin: left center;
    }
  }

  .navigation {
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    top: 7.1rem;
    right: 0;
    display: flex;
    align-items: center;
    pointer-events: none;

    @media only screen and (orientation: landscape) {
      top: 2rem;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
    }

    .button {
      padding: 0.35rem;
      margin: 0.06rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: goldman;
      background: none;
      border: none;
      background: linear-gradient(0deg, #f8af5c, #faba71, #fddf7b, #fcde7d);
      transition: filter 0.2s ease-in-out;
      border-radius: 0.5rem;
      filter: drop-shadow(0rem 0.1rem 0.1rem #2e3030);
      text-shadow: 0 0.04rem 0.06rem #e7d9d9;
      pointer-events: auto;

      @media only screen and (orientation: landscape) {
        padding: 0.3rem;
      }

      &:hover {
        filter: drop-shadow(0rem 0.1rem 0.1rem #6e6868);
      }

      &:active {
        background: linear-gradient(to bottom, #c2bfba, #fdd57d);
      }

      .link {
        text-decoration: none;
        color: #161616;

        .icon,
        .letters {
          padding: 0.58rem;
          text-align: start;
          border-radius: 0.3rem;
          display: block;
          width: 70vw;

          @media only screen and (orientation: landscape) {
            padding: 0.2rem;
            width: auto;
          }
        }

        .icon {
          filter: drop-shadow(0 0 0.2rem #14f83a);
          background: linear-gradient(0deg, #14f83a, #b2fa89f3);
          font-size: 1.2rem;
          color: #0d1e10;
          padding: 0.3rem;

          @media only screen and (orientation: landscape) {
            width: auto;
            background: radial-gradient(circle, #14f83a, #ffffff7c);
          }
        }

        .letters {
          padding: 0.4rem;
          transition: transform 0.15s ease-out, scale 0.25s ease-in-out;

          @media only screen and (orientation: landscape) {
            width: auto;
            padding: 0.3rem;
          }

          &:hover {
            scale: 1.01;
            transform: translateY(-10%);
          }
        }
      }
    }
  }

  .contact-info {
    position: absolute;
    top: 0.4rem;
    display: flex;
    flex-direction: column;
    left: 18vw;
    text-align: center;
    width: auto;
    padding: 0.2rem 1rem;
    font-size: 0.65rem;
    font-family: "Exo", sans-serif;
    color: #ffffff;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: flex-start;

    @media only screen and (orientation: landscape) {
      flex-direction: row;
      top: -0.5rem;
      font-size: 0.8rem;
    }

    .phone,
    .email {
      padding: 0.2rem 0.2rem;
      color: #ffffff;
      font-size: 1.2rem;
      margin: 0.5rem;
      border-radius: 50%;
      box-shadow: 0 0rem 0.1rem #202020;
      filter: drop-shadow(0 0.1rem 0.1rem #807c7c);

      @media only screen and (orientation: landscape) {
        font-size: 1rem;
      }
    }

    .link {
      color: inherit;
      text-decoration: none;
      margin: 0.7rem;
      letter-spacing: 0.08rem;
      transition: color 0.3s ease;

      &:hover {
        color: #fcb15b;
        text-shadow: 0 0.1rem 0.1rem #111010;
      }
    }
  }
}
