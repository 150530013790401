.social {
  .title-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    overflow: hidden;
    height: 50vw;
    width: 90vw;
    border-radius: 50%;
    box-shadow: inset 0 1rem 0.3rem black;
    background: linear-gradient(-90deg, #8a888b, #141d23);

    .title {
      text-shadow: 0 0 0.2rem white;
      z-index: 1;
      text-align: center;
      width: 60vw;
      position: absolute;
      border-radius: 50%;
      background: linear-gradient(to bottom, #00000009, #ffffff);
      padding: 2rem;
    }

    .img {
      height: 60vw;
      mask-image: linear-gradient(to bottom, #00000033, #000000);
    }
  }

  .content-container {
    width: 70vw;

    .sub-container .sub-title {
      text-align: center;
      background: linear-gradient(to bottom, grey, black);
      color: white;
      border-radius: 50%;
      padding: 0.5rem;
    }
  }

  @media only screen and (orientation: landscape) {
    .title-container {
      height: 18rem;
      width: 37rem;

      .title {
        width: 20rem;
      }

      .img {
        height: 22rem;
      }
    }
  }
}
