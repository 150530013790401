footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  .arrow {
    width: 20%;
    margin: 0;
    padding: 0;
    text-align: center;
    position: relative;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    font-size: 4rem;
    display: block;
    overflow: hidden;
    background: linear-gradient(to bottom, #4946464d, #49464677, #bebac2);
    box-shadow: 0 0 1rem #acf7e083;
    color: #0c0c0c;
  }

  .content {
    position: relative;
    text-align: center;
    background: linear-gradient(100deg, #fcb15b, #fcc36d, #fcb15b);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-family: goldman;
    width: 100vw;

    .company {
      margin-bottom: 7rem;
      text-align: start;
      line-height: 1.2rem;

      @media only screen and (orientation: landscape) {
        margin-bottom: 5rem;
      }

      .title {
        width: 25vw;
        font-size: 0.9rem;
        text-transform: uppercase;
        color: #08080c;

        @media only screen and (orientation: landscape) {
          font-size: 1.1rem;
        }
      }

      .text {
        line-height: 1.5rem;
        color: #08080c;
        font-size: 0.85rem;

        @media only screen and (orientation: landscape) {
          font-size: 1rem;
        }
      }
    }

    .services {
      text-align: center;
      margin-bottom: 7rem;
      display: flex;
      margin-left: 7vw;
      flex-direction: column;
      height: 9rem;

      .title {
        text-transform: uppercase;
        color: #08080c;
        font-size: 0.9rem;

        @media only screen and (orientation: landscape) {
          font-size: 1.1rem;
        }
      }

      .link {
        text-transform: uppercase;
        color: #08080c;
        padding: 0.3rem 1.5vw;
        font-size: 0.85rem;
        border-radius: 0.3rem;
        text-decoration: none;
        filter: drop-shadow(0rem 0.15rem 0.2rem #f3f2f0);
        transition: filter 0.1s ease-in-out, transform 0.25s ease-in-out;

        @media only screen and (orientation: landscape) {
          font-size: 1rem;
        }

        &:hover {
          filter: none;
          box-shadow: 0 0.1rem 0.1rem #777570;
        }
      }
    }

    .copyright {
      position: absolute;
      font-family: exo;
      bottom: 0;
      width: 100%;
      text-align: center;
      pointer-events: none;
      font-size: 0.85rem;

      @media only screen and (orientation: landscape) {
        font-size: 1rem;
      }

      .image {
        height: 0.85rem;
        margin: 0.2rem 0.4vw 0.1rem 0.4vw;

        @media only screen and (orientation: landscape) {
          height: 1rem;
        }
      }
    }
  }

  .drawer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    bottom: 2rem;
    background: #c6c6c6;
    color: rgb(0, 0, 0);
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    transition: left 0.3s ease;
    overflow: hidden;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
    background: linear-gradient(#96a69b, #83d784);
    background-size: 300% 100%;

    &:hover {
      .open_drawer {
        opacity: 0;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;

      .button {
        color: #414642;
        border: none;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        padding: 0.5rem;
        font-family: exo;
        filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(167, 158, 158, 0.456));

        &.cookies {
          background: radial-gradient(circle, #ffffff, #e8ca36);
        }

        &.rules {
          background: radial-gradient(circle, #ffffff, #e89e36);
        }

        &.privacy {
          background: radial-gradient(circle, #ffffff, #e84236);
        }

        &:hover {
          scale: 1.1;
          box-shadow: 0 0 0.3rem #343434;
          transition: 0.35s ease-in-out;
        }
      }
    }

    .open_drawer {
      font-size: 1.2rem;
      background: none;
      border: none;
      color: black;
      transform: scale(-1);
      transform: rotate(-90deg);
    }
  }
}

.modal {
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 8rem;
  position: fixed;
  width: 80vw;
  height: 80vh;
  background: #49464669;
}

.policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 80%;
  height: 80%;
  background: white;
  border-radius: 1rem;
  overflow-y: auto;
}

.close,
.accept {
}

.close {
  background: red;
  right: 0;
  position: absolute;
}

.rodo {
  background: none;
  border: none;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
