body {
  margin: 0;
  padding: 0;

  #root {
    overflow-x: hidden;
    overscroll-behavior-y: contain;
    background: linear-gradient(to bottom, #fafafa, #f1f1f1, #f2f2f2);

    main {
      margin-top: 7.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      article {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1vw 0;

        h1 {
          font-size: 1rem;
          text-transform: uppercase;
        }

        .content-container {
          width: 90vw;

          @media only screen and (orientation: landscape) {
            width: 70vw;
          }
        }
      }

      h1,
      span {
        font-family: goldman;
      }

      h2,
      p,
      label,
      i {
        font-size: 0.75rem;
        font-family: exo;
        @media only screen and (orientation: landscape) {
          font-size: calc(0.9rem - 0.1vw);
        }
      }

      h3 {
        font-family: exo;
      }

      .audit {
        width: 90vw;

        @media only screen and (orientation: landscape) {
          width: 70vw;
        }

        .title {
          border-top: solid 0.2rem;
          border-bottom: solid 0.2rem;
          border-color: #01cc69;
          padding: 0.3rem 5vw;
        }

        .img {
          max-width: 90vw ;
        }
      }
    }
  }
}

.home {
  .vid {
    width: 100vw;
    height: 100%;
    object-fit: cover;

    @media only screen and (orientation: landscape) {
      max-width: 70vw;
    }
  }

  .checkout,
  .indicators {
    width: 100vw;
    margin: 0.2rem 0;
    background: linear-gradient(-45deg, #fca849, #f8e091, #fca849);
  }

  .checkout {
    filter: drop-shadow(0 -0.5rem 0.1rem #ffffff);
    position: relative;
    bottom: 0.7rem;
    text-align: center;
    font-size: 1.2rem;
    display: inline-block;
    border-top-left-radius: 35%;
    border-top-right-radius: 35%;
    padding: 0.6rem;
    text-shadow: -0.06rem -0.06rem #f8f7f3, 0.06rem -0.06rem #f8f7f3,
      -0.06rem 0.06rem #f8f7f3, 0.06rem 0.06rem #f8f7f3;

    @media only screen and (orientation: landscape) {
      font-size: 1.5rem;
    }
  }

  .indicators {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
    height: 12rem;

    @media only screen and (orientation: landscape) {
      height: 20vw;
    }

    .button {
      cursor: pointer;

      &:nth-child(odd) {
        margin: 0 -10% 4rem -10%;

        @media only screen and (orientation: landscape) {
          margin: 0;
        }
      }

      &:nth-child(even) {
        margin-top: 5rem;

        @media only screen and (orientation: landscape) {
          margin: 0;
        }
      }

      &:first-child {
        margin-left: 1vw;
      }

      &:last-child {
        margin-right: 1vw;
      }

      .background {
        padding: 0.4rem;
        clip-path: circle(45% at 50% 50%);
        height: 5.5rem;
        display: block;

        @media only screen and (orientation: landscape) {
          width: 16vw;
          height: auto;
        }
      }

      .title {
        padding: 2.3rem 0;
        background: #494646;
        border-radius: 50%;
        text-shadow: 0 0.1rem 0.1rem #050505;
        border: solid 0.3rem #faf8f2;
        font-size: 0.65rem;
        width: 5.5rem;
        color: #fcfcfc;
        text-align: center;
        filter: drop-shadow(0rem 0.1rem 0.1rem #1a1a196b);

        @media only screen and (orientation: landscape) {
          width: 16vw;
          padding: 7vw 0;
          font-size: 1.6vw;
        }
      }
    }
  }

  .slide {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3.5rem 0 1rem 0;
    padding: 1rem;
    transition: transform 0.5s ease-in-out;

    @media only screen and (orientation: landscape) {
      margin-top: 2vw;
    }

    .text {
      text-align: center;
      font-size: 0.8rem;
      color: #000000;
      margin: 5rem 0.5vw 2rem 0.5vw;
      width: 90vw;

      @media only screen and (orientation: landscape) {
        font-size: 1rem;
        margin-top: 17vw;
      }
    }

    .link {
      margin-bottom: -1rem;
      display: flex;
      width: 100vw;
      height: 4.5rem;
      background: #494646;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      transition: filter 0.2s ease-in-out, transform 0.25s ease-in-out;
      filter: drop-shadow(0rem 0.1rem 0.1rem #d8cdcd);

      @media only screen and (orientation: landscape) {
        height: 5.5rem;
        margin-bottom: 0;
      }

      &:hover {
        transform: scale(1.02);
        background: radial-gradient(circle, #fcb15b, #fcc36d, #fcb15b);
        color: #000000;
      }

      .to {
        position: relative;
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        color: #ffffff;
        font-size: 1rem;
        overflow: hidden;
        margin-block-start: 0;
        margin-block-end: 0;

        @media only screen and (orientation: landscape) {
          font-size: 1.1rem;
        }

        .icon {
          margin-top: 0.3rem;
          padding: 0.2rem;
        }

        &:hover {
          color: #000000;
        }

        .image {
          margin-right: 2vw;
          height: 4rem;
          filter: drop-shadow(0rem 0.2rem 0.2rem #faf9f6);

          @media only screen and (orientation: landscape) {
            height: 5rem;
          }
        }
      }
    }
  }

  .opinion {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.7rem;
    text-transform: uppercase;

    .icon {
      font-size: 3.5rem;
      color: #868282;
      transform: rotate(90deg);
    }

    .title {
      font-size: 1.1rem;
      padding: 0.8rem;
      text-align: center;
      width: 70vw;
      box-shadow: 0 0.1rem 0.2rem #ddd6c8;
      color: #0e0d0d;
      background: linear-gradient(to bottom, transparent, transparent, #c2bfba);
      border-radius: 30%;
      border-top: solid 0.2rem #f7bb61;
      border-bottom: solid 0.2rem #f7bb61;
      margin-inline-end: 0;
    }

    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 1rem;
      height: 15rem;
      border-radius: 0.3rem;
      margin-top: -3rem;

      .name {
        color: #0c0b0b;
        padding: 0.2rem;
        font-size: 1rem;
        width: 100vw;
        padding: 0.2rem;
        background: radial-gradient(circle, transparent, transparent, #c2bfba);
        
        @media only screen and (orientation: landscape) {
          margin-top: 2rem;
        }
      }

      .star {
        margin-top: 1rem;
        color: #ff9800;
        font-size: 1.1rem;
      }

      .comment {
        font-size: 0.8rem;
        text-align: center;
        line-height: 1.6;
        width: 80vw;
        margin-top: 2rem;
      }
    }
  }

  .motivation {
    filter: drop-shadow(0 0.1rem 0.1rem #d1c7b1);
    clip-path: polygon(100% 80%, 50% 100%, 0 80%, 0 0, 50% 20%, 100% 0);
    background: #3a39398a;

    @media only screen and (orientation: landscape) {
      width: 20rem;
    }

    .text {
      margin-top: -1rem;
      max-width: 75vw;
      text-align: center;
      clip-path: polygon(100% 80%, 50% 100%, 0 80%, 0 0, 50% 20%, 100% 0);
      background: linear-gradient(90deg, #dbdddc, #ffffff, #dbdddc);
      color: #000000;
      padding: 5rem 2vw;
      letter-spacing: 0.2rem;
      line-height: 1.5rem;
      transform: perspective(400px) rotateX(10deg);
      display: inline-block;
    }
  }

  .form-background {
    background: linear-gradient(90deg, #a7acaa, #cac7ba, #a7acaa);
    margin: 3rem;
    border-radius: 0.5rem;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (orientation: landscape) {
      width: 30rem;
    }

    .contact-form {
      width: 82%;
      margin: 2rem;
      padding: 0.5rem;
      border: solid 0.7rem #ffffff;
      background: linear-gradient(100deg, #fcb15b, #fcc36d, #fcb15b);
      border-radius: 0.4rem;
      box-shadow: 0 0 0.7rem #b4b1b1f8;

      .title {
        color: #000000;
        padding: 0.1rem;
        border-top: solid 0.2rem white;
        border-bottom: solid 0.2rem white;
        box-shadow: 0 -0.2rem 0.05rem white, 0 0.2rem 0.05rem white;
        border-radius: 3rem;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0 0.1rem 0.1rem #fffafa;
      }

      .form {
        margin-left: 3vw;
        display: flex;
        flex-direction: column;
        width: 80%;

        .field {
          padding: 0.1rem;
          border-top-right-radius: 0.5rem;
          color: #000000;
          margin-top: 1rem;
          font-size: 0.8rem;
          width: 40vw;

          &.agreement {
            background: transparent;
            color: #000000;
            width: 100%;
          }
        }

        .input {
          position: relative;
          width: 100%;
          padding: 0.4rem;
          border: solid 0.15rem #fffdfc;
          border-top-left-radius: 0.6rem;
          border-bottom-right-radius: 0.6rem;

          &.message {
            height: 4rem;
            resize: none;
          }
        }

        .topic {
          filter: drop-shadow(0rem 0.1rem 0.1rem #848686);
          padding: 0.8rem;
          border-radius: 0.3rem;
          font-size: 0.75rem;
          width: 54vw;

          text-transform: uppercase;
          border: none;
        }

        .required {
          color: red;
          font-size: 1.2rem;
          font-style: bold;
        }

        .send {
          border: none;
          color: #0e0d0d;
          text-transform: uppercase;
          font-family: goldman;
          padding: 0.7rem;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 0.8rem;
          margin: 1rem;
          border-radius: 0.5rem;
          transition: filter 0.1s ease-in-out, transform 0.25s ease-in-out,
            background-size 0.3s ease-out;

          &:hover {
            transform: scale(1.05);
            background: #494646;
            color: white;
          }
        }
      }
    }
  }
}
