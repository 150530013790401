.email {
  .title-container {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 75%,
      75% 75%,
      75% 100%,
      50% 75%,
      0% 75%
    );
    padding-bottom: 4rem;
    justify-content: center;
    background: linear-gradient(-90deg, #88765f8e, transparent, #88765f8e);
    box-shadow: 0 -0.1rem -0.2rem #f3f1ea;

    @media only screen and (orientation: landscape) {
      width: 60vw;
    }

    .title {
      padding-left: 2rem;
      text-align: start;
    }

    .img {
      height: 13rem;
    }
  }

  .content-container {
    box-shadow: 0 0.1rem 0.2rem grey;

    .intro,
    .ending {
      padding: 0.3rem;
    }

    .sub-container {
      .sub-title {
        padding: 0.1rem;
        background: linear-gradient(to right, #dbba75, #f3ae59);
      }

      .sub-text {
        padding: 0.5rem;
      }
    }
  }
}
