.technologies {
  .title-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    width: 100vw;

    .title {
      position: absolute;
      width: 100%;
      padding: 3rem 0 3rem 0;
      text-align: center;
      text-shadow: 0 0.1rem 2rem #222121;
      color: white;
    }

    .img {
      width: 100vw;
      height: 100%;
    }
  }

  .content-container {
    align-items: center;

    .sub-container {
      display: flex;
      flex-direction: column;

      .sub-title {
        box-shadow: 0 0.1rem 0.2rem #858686;
        text-transform: uppercase;
        border-top-right-radius: 3rem;
        text-align: center;
        padding: 0.2rem;
      }

      .sub-text {
        text-align: end;
        padding: 0.5rem;
        margin: 0 2vw;
      }
    }

    .ending {
      text-align: center;
      text-transform: uppercase;
    }
  }

  @media only screen and (orientation: landscape) {
    .title-container {
      margin-top: calc(3rem + 2vw);
      height: 17rem;

      .title {
        width: 90vw;
        letter-spacing: 0.3rem;
      }

      .img {
        height: 100%;
        width: auto;
      }
    }
    .content-container {
      .sub-container {
        .sub-title{
          align-self: center;
          width: 80%;
        }
        .sub-text {
          text-align: start;
        }
      }
    }
  }
}

.webapp .technologies {
  .title-container {
    .title {
      background: linear-gradient(to top, #7991ad9d, #7991ada1);
    }
  }

  .content-container {
    .sub-container {
      .sub-title {
        background: linear-gradient(to top, #7991ad9f, #7991ad91);
      }
    }
  }
}

.graphics .technologies {
  .title-container .title {
    background: linear-gradient(to top, #c5daf8dc, #636260a1);
  }

  .content-container {
    .sub-container .sub-title {
      text-shadow: 0 0.1rem 0.5rem #92d9eb;
      background: linear-gradient(to top, #c5daf8dc, #6362604d);
    }
  }
}

.support {
  .technologies {
    .title-container .title {
      background: linear-gradient(to top, #b1ac81c2, #c4ad809f);
    }

    .content-container {
      .sub-container .sub-title {
        background: linear-gradient(to top, #b1ac81a8, #c4ad809f);
      }
    }
  }
}
