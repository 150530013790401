.advantages {
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      clip-path: polygon(50% 100%, 100% 51%, 90% 0, 10% 0, 0 51%);
      width: 13rem;
      text-align: center;
      letter-spacing: 0.1rem;
    }

    .img {
      position: absolute;
      height: 12rem;
      margin-top: 9rem;
      filter: drop-shadow(0 0.1rem 0.1rem #7d7e7d);
    }
  }

  .sub-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sub-title {
      padding: 0.25rem;
      text-align: center;
      width: 70vw;
      border-top-left-radius: 4rem;
      border-bottom-right-radius: 4rem;
    }

    .sub-text {
      text-align: start;
    }
  }

  .ending {
    background: white;
    border-radius: 0.3rem;
    padding: 0.5rem 4vw;
    text-transform: uppercase;
    border-top: solid 0.2rem #c5b49a;
    border-bottom: solid 0.2rem #c5b49a;
  }

  @media only screen and (orientation: landscape) {
    .title-container {
      .title {
        width: 15rem;
      }
    }

    .sub-container {
      .sub-title {
        width: 30rem;
      }

      .sub-text {
        width: 50vw;
      }
    }
    .ending {
      width: 60vw;
    }
  }
}

.outsourcing .advantages {
  .title-container {
    .title {
      padding: 1rem 5vw 9rem 5vw;
      text-shadow: 0 0.1rem 0.1rem #d3d4d3;
      background: #c5b49ab7;
    }

    .img {
      filter: drop-shadow(0 0.1rem 0.1rem #8f8473a1) grayscale(50%);

      @media only screen and (orientation: landscape) {
        margin-top: 6rem;
      }
    }
  }

  .sub-container .sub-title {
    padding: 0.25rem;
    background: #c5b49ab7;
  }
}

.support .advantages {
  .title-container {
    .title {
      padding: 1rem 5vw 9rem 5vw;
      text-shadow: 0 0.1rem 0.1rem #b8bbb5;
      background: #dbca9a;
    }
  }

  .sub-container .sub-title {
    background: #dbca9a;
  }
}
