.trends {
  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .title {
      position: relative;
      z-index: 1;
      margin-right: -8rem;
      padding: 13rem 13vw 0 10vw;
      color: #312919;
      border-bottom: solid 0.2rem #a0809c;
      text-shadow: 0 0.1rem 0.2rem #faf1f1;
      letter-spacing: 0.2rem;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
      background: linear-gradient(90deg, #a0809c, transparent);
    }

    .img {
      height: 14rem;
      filter: drop-shadow(0 0.1rem 0.2rem #f3deb7);
    }
  }

  .sub-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .sub-title {
      padding: 0.2rem;
      border-bottom-left-radius: 2rem;
      width: 80vw;
      text-align: center;
      background: linear-gradient(90deg, #a0809cd3, transparent);
    }
    .sub-text {
      text-align: end;
    }
  }

  .ending {
    text-align: center;
    margin: 1rem 4vw;
    text-transform: uppercase;
  }

  @media only screen and (orientation: landscape) {
    .sub-container .sub-title{
      width: 50vw;
    }
  }
}
