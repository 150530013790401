.aida {
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(0rem 0.2rem 0.2rem #b9b8b9);

  @media only screen and (orientation: landscape) {
    margin-bottom: 7vw;
  }

  .page-title {
    letter-spacing: 0.4rem;
    text-align: center;
    font-size: 1.2rem;
    color: #161616;
    text-shadow: -0.06rem -0.06rem #fdf8f8, 0.06rem -0.06rem #fdf8f8,
      -0.06rem 0.06rem #fdf8f8, 0.06rem 0.06rem #fdf8f8;
  }

  .background {
    padding: 0.4rem 0;
    width: 90vw;
    text-align: center;
    background: linear-gradient(
      to bottom,
      transparent,
      transparent,
      #c2bfba,
      #fcd27f,
    );
    border-radius: 30%;
    border-top: solid 0.2rem #dddad4;
    border-bottom: solid 0.2rem #656566;
    margin: 1.5rem 0;

    @media only screen and (orientation: landscape) {
      width: 50vw;
    }
  }

  .content-container {
    display: flex;
    align-items: center;
    padding: 0.2rem;

    @media only screen and (orientation: landscape) {
      margin: -2rem 0;
      margin-top: 2vw;
    }

    &:nth-child(even) {
      flex-direction: row;
      margin-left: 25vw;
      text-align: end;

      @media only screen and (orientation: landscape) {
        margin-left: 40vw;
      }
    }

    &:nth-child(odd) {
      flex-direction: row-reverse;
      text-align: start;
      margin-right: 25vw;

      @media only screen and (orientation: landscape) {
        margin-right: 40vw;
      }
    }

    .vid {
      width: 90vw;

      @media only screen and (orientation: landscape) {
        width: 40vw;
      }
    }

    .mask,
    .text {
      
      position: absolute;
    }

    .mask {
      width: 90vw;
      height: calc(10rem - 3vw);
      background: #ffffff89;

      @media only screen and (orientation: landscape) {
        width: 40vw;
      }
    }

    .text {
      width: 75vw;
      @media only screen and (orientation: landscape) {
        width: 40vw;
      }
    }
  }
}
