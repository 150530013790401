.content {
  .title-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 2rem;

    .title {
      padding-left: 0.5rem;
      width: 30vw;
    }

    .img {
      box-shadow: 0 0 0.2rem #6a6a6a, 0.1rem -0.1rem 0.3rem #929191;
      border-radius: 2rem;
      border-top-left-radius: 0;
      height: 13rem;
    }
  }

  .content-container {
    margin-top: 1.5rem;
    padding: 1rem;
    box-shadow: 0 0 0.2rem #6a6a6a, 0.1rem -0.1rem 0.3rem #929191;
    border-radius: 2rem;
    border-top-right-radius: 0;

    .sub-container .sub-title {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      padding: 0.2rem;
      box-shadow: 0.1rem 0 0.2rem #6a6a6a;
      text-align: end;
      background: linear-gradient(to left, #e3926d, #71cbe4, transparent);
    }
  }
}
