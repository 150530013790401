.diagram {
  height: 15rem;
  width: 15rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (orientation: landscape) {
    height: 25rem;
    width: 25rem;
  }

  .circle {
    position: absolute;
    border-radius: 50%;

    &.n1,
    &.n2,
    &.n3 {
      height: 75%;
      width: 75%;
    }

    &.n2,
    &.n3 {
      margin-top: 70%;
    }

    &.n1 {
      margin-right: -10%;
      background: #ff000069;
    }

    &.n2 {
      margin-right: 30%;
      background: #00ff0069;
    }

    &.n3 {
      margin-right: -50%;
      background: #0000ff69;
    }
  }

  .parts {
    font-family: goldman;
    position: absolute;
    width: max-content;

    &.utopia,
    &.fast,
    &.qualitatively,
    &.cheaply {
      font-size: 0.8rem;

      @media only screen and (orientation: landscape) {
        font-size: 1rem;
      }
    }

    &.expensive,
    &.more-time,
    &.awry {
      font-size: 0.67rem;

      @media only screen and (orientation: landscape) {
        font-size: 0.8rem;
      }
    }

    &.qualitatively {
      margin: 0 0 25% 10%;
    }

    &.expensive {
      margin: 18% 35% 0 0;
      border-bottom: solid 0.1rem #b8fd16;
    }

    &.more-time {
      margin: 18% 0 0 55%;
      border-bottom: solid 0.1rem #8b0ca5;
    }

    &.utopia {
      margin: 45% 0 0 10%;
      border: solid 0.1rem black;
      background: #e7e4e4;
    }

    &.awry {
      margin: 85% 0 0 10%;
      border-bottom: solid 0.1rem #2f2ff8;
    }

    &.fast {
      margin: 75% 60% 0 0;
    }

    &.cheaply {
      margin: 75% 0 0 75%;
    }
  }
}

.table-switcher {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 5rem;

  @media only screen and (orientation: landscape) {
    margin-top: 8rem;
  }

  .button {
    background: #494646;
    color: whitesmoke;
    font-family: exo;
    text-shadow: 0 0.1rem 0.1rem #49464698;
    text-align: center;
    font-size: 0.6rem;
    border-radius: 0;
    margin: 0 0.1vw;

    @media only screen and (orientation: landscape) {
      font-size: 0.9rem;
    }

    &:hover {
      background: darkgrey;
    }
  }

  .active {
    position: absolute;
    background: linear-gradient(90deg, #269229, #97ffa7, #269229);
    height: 0.2rem;
    margin-bottom: calc(2.2rem + 2vw);
    filter: drop-shadow(0 0.1rem 0.1rem #97ffa7);

    @media only screen and (orientation: landscape) {
     margin-bottom: 4rem;
    }
  }
}

.table {
  border: solid 0.2rem #494646;
  border-radius: 1rem;

  .row {
    border: solid 0.2rem #494646;

    &.head {
      background: linear-gradient(-45deg, #fca849, #f8e091, #fca849);
    }

    .price {
      font-family: exo;
      border: solid 0.15rem #494646;
    }

    .text {
      text-transform: uppercase;
      border: solid 0.15rem #494646;
      font-family: exo;
      color: #150333;
    }

    .profit {
      padding: 0;
      text-align: start;
      font-family: exo;
      list-style: none;
      font-size: 0.8rem;
      width: 40vw;
    }
  }
}

.star-text {
  padding: 0.3rem;
  border-radius: 0.3rem;
  border: solid 0.15rem #49464698;
  font-family: exo;
  font-size: 0.7rem;
  background: #daeef175;

  @media only screen and (orientation: landscape) {
    font-size: 0.8rem;
  }
}
