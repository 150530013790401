.process {
  .title-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    width: 100vw;
    filter: drop-shadow(0 0.1rem 0.1rem #838080);

    .title {
      position: absolute;
      padding: 0.2rem;
      border-top-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      color: #292828;
      text-align: center;
    }

    .img {
      height: 13rem;
    }
  }

  .content-container {
    .sub-container {
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      box-shadow: 0 -0.1rem 0.3rem #99949480;
      border-bottom: dotted;

      .sub-title {
        clip-path: polygon(
          0 5%,
          60% 5%,
          85% 0,
          100% 50%,
          85% 100%,
          60% 95%,
          0 95%
        );
        margin-left: -4vw;
        padding: 0.2rem;
      }

      .sub-text {
        padding: 0.5rem;
        margin: 0 2vw;
      }
    }

    .ending {
      text-align: center;
    }
  }

  @media only screen and (orientation: landscape) {
    .title-container {
      height: calc(13rem + 5vw);
      margin-top: 2rem;

      .title {
        width: 50vw;
        font-size: 1rem;
      }

      .img {
        width: auto;
        height: 100%;
      }
    }

    .content-container {
      .sub-container {
        flex-direction: row;

        .sub-title {
          width: 120%;
          padding-right: 3vw;
          align-self: flex-start;
          margin-left: 0;
          text-align: start;
        }
      }
    }
  }
}

.webapp .process {
  .title-container .title {
    background: linear-gradient(to right, #b4c0c9, #e2e4e6, #b4c0c9);
  }

  .content-container .sub-container .sub-title {
    background: linear-gradient(to right, #b4c0c9, #e2e4e6, #b4c0c9);
  }
}

.graphics .process {
  .title-container .title {
    background: linear-gradient(to right, #f7c699, #f5d2bd, #f7c699);
  }

  .sub-container .sub-title {
    background: linear-gradient(to right, #f7c699, #f5d2bd9d, #f7c699);
  }
}

.outsourcing .process {
  .title-container .title {
    background: linear-gradient(to right, #dfb84e, #f8d491, #dfb84e);
  }

  .sub-container .sub-title {
    background: linear-gradient(to right, #dfb84e, #f8d491af, #dfb84e);
  }
}
