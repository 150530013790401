.about {
  .info,
  .goals {
    border-radius: 0.4rem;
    padding: 1.2rem;

    @media only screen and (orientation: landscape) {
      width: 80vw;
    }

    .intro {
      display: flex;
      align-items: center;

      .text {
        font-size: 0.8rem;

        @media only screen and (orientation: landscape) {
          font-size: 1rem;
        }
      }

      .image {
        height: 10rem;
        margin-left: 0.5vw;
      }
    }

    .container {
      @media only screen and (orientation: landscape) {
        width: 60vw;
      }

      .sub-title {
        color: #ffffff;
        background: #494646;
        text-align: center;
        font-size: 0.9rem;
        padding: 0.25rem;
        text-transform: uppercase;
        border-radius: 0.3rem;

        @media only screen and (orientation: landscape) {
          font-size: 1.1rem;
        }
      }

      .sub-text {
        font-size: 0.8rem;

        @media only screen and (orientation: landscape) {
          font-size: 1rem;
        }
      }
    }
  }

  .info {
    .intro {
      flex-direction: row;

      .image {
        right: 0;
        filter: drop-shadow(0rem 0.1rem 0.1rem #573d12) contrast(150%);

      }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media only screen and (orientation: landscape) {
      }

      .sub-title {
        margin-top: 0.9rem;
        filter: drop-shadow(0.1rem 0.1rem 0.2rem #b7bbbb);

        @media only screen and (orientation: landscape) {
          margin-top: 4vw;
          width: 40vw;
          text-align: center;
          background: #494646;
        }
      }

      .quote {
        font-size: 0.8rem;
        padding: 0.5rem;
        border-radius: 0.3rem;
        border-left: 0.5rem solid #494646;
        background: linear-gradient(to bottom, #fcba6f, #fcc36dc0);

        @media only screen and (orientation: landscape) {
          margin-left: 16vw;
          width: 60vw;
          font-size: 0.9rem;
        }
      }
    }
  }

  .goals {
    .intro {
      flex-direction: row-reverse;

      .image {
        left: 0;
        filter: drop-shadow(0rem 0.1rem 0.1rem #c9cfcc);
      }
    }

    .container {
      .sub-title {
        letter-spacing: 0.2rem;
        margin-top: 0.7rem;
        filter: drop-shadow(0.1rem 0.1rem 0.2rem #858686);
      }
    }
  }
}
