.couple {
  .title {
    margin-top: 5vw;
    position: relative;
  }
  .content-container {
    .sub-title {
      font-size: 1rem;
      letter-spacing: 0.2rem;
      border-radius: 0.2rem;
      text-align: center;
      padding: 0.2rem;
    }

    .first,
    .second {
      font-size: 0.8rem;

      .man,
      .woman {
        padding: 0.15rem;
        margin: 0.2rem;
        border-radius: 0.3rem;
        font-size: 0.8rem;
      }
    }
  }

  .ending {
    text-align: center;
    padding: 0.5rem;
    font-size: 0.85rem;
  }

  @media only screen and (orientation: landscape) {
    .ending{
      width: 70vw;
    }
  }
}

.marketing .couple {
  .content-container {
    .sub-title {
      padding-bottom: 1rem;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 95% 100%, 95% 70%, 0% 70%);
      filter: drop-shadow(0rem 0.1rem 0.1rem #504f4ea2);
      background: linear-gradient(90deg, #ffbe69, #fcc35a);
      text-shadow: 0 0rem 0.1rem #dbd1d1;
    }

    .first,
    .second {
      line-height: 1.5rem;

      .man,
      .woman {
        padding: 0.5rem;
        clip-path: polygon(
          0% 20%,
          60% 20%,
          60% 0%,
          100% 50%,
          60% 100%,
          60% 80%,
          0% 80%
        );
        filter: drop-shadow(0rem 0.2rem 0.1rem #c4bfb6);
      }
    }

    .first .man {
      background: linear-gradient(-45deg, #f3dc58, #ecff3e);
    }

    .second .woman {
      background: linear-gradient(-45deg, #5dbe50, #80e473);
    }
  }

  .ending {
    box-shadow: 0 0 0.2rem #869799;
    background: #ffffff;
    text-shadow: 0 0 0.1rem white;
  }
}

.support .couple {
  .title {
    text-align: center;
    font-size: 3rem;
    padding: 1.5rem;
    color: #111111;
    border-radius: 2rem;
    box-shadow: 0 0.1rem 0.1rem #969791;
  }

  .content-container {
    display: flex;
    flex-direction: row;

    .man,
    .woman {
      text-transform: uppercase;
      filter: drop-shadow(0.1rem 0.1rem #9ea08d);
      text-decoration: none;
    }

    .first,
    .second {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .first {
      width: 35%;
      .man {
        background: linear-gradient(0deg, #f7757594, #f1442d60);
      }
    }

    .second {
      width: 65%;

      .woman {
        background: linear-gradient(0deg, #0cca25ce, #95fe7baf);
      }
    }
  }

  .ending {
    letter-spacing: 0.1rem;
    padding: 0.5rem;
  }
}
