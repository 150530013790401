.seo {
  .title-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    box-shadow: inset 0 -0.2rem 0.2rem #d2e2e6;
    filter: drop-shadow(0 -0.2rem 0.2rem #acc0c5);

    .title {
      width: 100vw;
      text-align: center;
    }

    .img {
      width: 35vw;
      clip-path: polygon(
        10% 25%,
        20% 10%,
        35% 0%,
        65% 0%,
        80% 10%,
        90% 25%,
        80% 45%,
        65% 50%,
        75% 100%,
        25% 100%,
        35% 50%,
        20% 45%
      );
    }
  }

  .content-container {
    .sub-title {
      box-shadow: 0.2rem -0.2rem 0.2rem #d2e2e6;
      padding: 0.3rem;
      text-align: center;
      border-bottom: double;
      border-left: double;
      border-top-right-radius: 0.5rem;
    }
  }

  @media only screen and (orientation: landscape) {
    .title-container {
      .img {
        width: auto;
        height: 15rem;
      }
    }
  }
}
