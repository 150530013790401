.offerings {
  .title-container {
    margin-top: 6vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15rem;
    height: 15rem;
    background-size: 100% 150%;
    background-position: 100% - 10%;
    background-image: radial-gradient(ellipse, #b1d1fc, transparent, #b1d1fc);
    clip-path: polygon(
      20% 0%,
      80% 0%,
      100% 20%,
      100% 80%,
      80% 100%,
      20% 100%,
      0% 80%,
      0% 20%
    );

    .title {
      text-align: center;
      border-bottom: solid 0.2rem #f37d7d;
    }

    .img {
      margin-top: -1rem;
      height: 10rem;
    }
  }
  .content-container {
    .sub-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .sub-title {
        text-align: center;
        background: #b1d1fc6b;
        width: 20rem;
        border-top: solid 0.2rem #b1d1fc;
        border-bottom: solid 0.2rem #b1d1fc;
        border-radius: 3rem;
      }

      .sub-text {
        width: 20rem;
      }
    }
  }

  .ending {
    text-transform: uppercase;
    text-align: end;
  }

  @media only screen and (orientation: landscape) {
    .title-container {
      width: 50vw;
      height: 25vw;

      .title {
        margin-top: 4vw;
      }

      .img {
        height: 15vw;
      }
    }

    .content-container .sub-container {
      .sub-title {
        width: 30rem;
      }

      .sub-text {
        width: 60vw;
      }
    }

    .ending {
      width: 70vw;
      text-align: center;
    }
  }
}
