.ppc {
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img {
      margin-top: 3vw;
      z-index: 0;
      position: absolute;
      height: 45vw;
    }

    .title {
      padding: 0 20vw;
      padding-top: 45vw;
      z-index: 1;
      background: linear-gradient(0deg, #fcd283, transparent, #506b72);
      text-align: center;
      clip-path: polygon(
        100% 35%,
        80% 35%,
        80% 100%,
        20% 100%,
        20% 35%,
        0% 35%,
        50% 0
      );
    }
  }

  .content-container {
    .sub-container {
      .sub-title {
        text-align: center;
        color: #e79d13;
        text-transform: uppercase;
        box-shadow: 0 0.1rem 0.1rem #a19898;
      }

      .sub-text {
        background: linear-gradient(90deg, transparent, #fcd28377);
      }
    }
  }

  @media only screen and (orientation: landscape) {
    .title-container {
      .img {
        height: 20rem;
      }

      .title {
        width: 20rem;
        padding: 0 17vw;
        padding-top: 20rem;
      }
    }
  }
}
